import { tv } from 'tailwind-variants'

export const styles = tv({
  slots: {
    container: 'w-full flex gap-2 rounded-xl p-4',
    line: 'w-[3px] shrink-0 rounded-sm',
    content: 'grow',
  },
  variants: {
    variant: {
      critical: {
        container: 'bg-status-critical-00',
        line: 'bg-status-critical',
      },
      warning: {
        container: 'bg-status-warning-00',
        line: 'bg-status-warning',
      },
      success: {
        container: 'bg-status-success-00',
        line: 'bg-status-success',
      },
      info: {
        container: 'bg-brand-primary-light-10',
        line: 'bg-brand-primary-rest',
      },
    },
  },
  defaultVariants: {
    variant: 'info',
  },
})
