import React, { isValidElement } from 'react'
import { Body, BodySize, BodyType } from '~/scalis-components/core/typography'
import { styles } from './alert.styles'

export type AlertProps = {
  title?: string
  message: React.ReactNode
  variant?: 'critical' | 'warning' | 'success' | 'info'
}

export const Alert = ({ message, title, variant = 'info' }: AlertProps) => {
  const { container, line, content } = styles({ variant })

  return (
    <div className={container()}>
      <div className={line()} />

      <div className={content()}>
        {title && (
          <Body
            size={BodySize.small}
            type={BodyType.emphasys}
          >
            {title}
          </Body>
        )}
        {isValidElement(message) ? message : <Body size={BodySize.small}>{message}</Body>}
      </div>
    </div>
  )
}
